import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {programNonPCI, urlHost} from '../constants'
import {getCredentialsHeader, isDealer, isMexico, isSameScriptType} from '../functions'

export const getAvailableInstallDates = createAsyncThunk('settings/getAvailableInstallDates', async (caseId, thunkAPI) => {
    const response = await fetch(`${urlHost}/srv_hughes/getAvailability.php`, {
        body: JSON.stringify({caseid: caseId}),
        headers: {...getCredentialsHeader(thunkAPI.getState()), 'Content-Type': 'application'},
        method: 'post'
    })
    return response.json()
})

export const getStringTranslations = createAsyncThunk('settings/getStrTranslation', async (arg, thunkAPI) => {
    const response = await fetch(`${urlHost}/srv_calldata/new/getScriptTranslations.php`)
    if (!response.ok) {
        throw new Error('Error loading str translation')
    }

    return response.json()
})

export const getSectionNotes = createAsyncThunk('settings/getSectionNotes', async (arg, thunkAPI) => {
    const {settings, customer: {data}} = thunkAPI.getState()
    let lang = settings.lang
    if (data.program === 'external') {
        lang = 'en-external'
    }
    const response = await fetch(`${urlHost}/srv_calldata/new/getSectionNotes.php?lang=${lang}`, {
        headers: {
            ...getCredentialsHeader(thunkAPI.getState())
        }
    })
    if (!response.ok) {
        throw new Error('Error loading str translation')
    }

    return response.json()
})


export const login = createAsyncThunk('settings/login', async ({username, password}) => {
    let b64 = btoa(`${username}:${password}`)
    const response = await fetch(`${urlHost}/srv_calldata/new/authorization.php`, {
        method: 'post', headers: {
            'Authorization': `Basic ${b64}`
        }
    })

    return [response.ok, username, password]
})

const getLabelBasedOnEnglish = ({labelsByLang, lang}) => {
    const english = 'en'
    const flatten = (obj) => {
        const ret = {}
        for (const objKey in obj) {
            ret[objKey] = obj[objKey][0].translation
        }

        return ret
    }
    if (lang === english) {
        return flatten(labelsByLang[lang])
    }
    const labels = Object.keys(labelsByLang[english])
    const ret = {}
    const target = labelsByLang[lang]
    for (const label of labels) {
        if (target.hasOwnProperty(label)) {
            ret[label] = target[label][0].translation
        } else {
            ret[label] = label
        }
    }

    return ret
}

export const settingsSlice = createSlice({
    name: 'settings', initialState: {
        lang: 'en',
        appLoadingState: '',
        labels: {},
        labelsByLang: {},
        orderType: '',
        howDidYouHear: ['Directories', 'Google search', 'Online Ad', 'Received mail', 'Referred by Company', 'Referred by friend', 'Social Media', 'TV commercial', 'Website', 'YouTube',],
        howDidYouHearDealer: ['Banner Ad', 'Dealer Website', 'Event', 'Google search', 'Mailer or Postcard', 'Online Ad', 'Radio', 'Social Media', 'TV commercial', 'Yard Sign'],
        howDidYouHearPR: ['Facebook', 'Google Search', 'Newspaper or Magazine', 'Online Ad', 'Radio', 'Received Mailer', 'Referred by Friend', 'TV Commercial', 'Website', 'YouTube',],
        howDidYouHearMX: ['Amigo', 'Distribuidor autorizado', 'Entrevista lider de opinion', 'Envento de activacion', 'Facebook', 'Folleto', 'Google', 'HughesNet website pagina corporativa', 'No recuerdo', 'Prensa periodicos', 'Radio', 'TV', 'YouTube',],
        usStates: [{name: 'Alabama', code: 'AL'}, {name: 'Alaska', code: 'AK'}, {
            name: 'Arizona', code: 'AZ'
        }, {name: 'Arkansas', code: 'AR'}, {name: 'California', code: 'CA'}, {
            name: 'Colorado', code: 'CO'
        }, {name: 'Connecticut', code: 'CT'}, {name: 'Delaware', code: 'DE'}, {
            name: 'Florida', code: 'FL'
        }, {name: 'Georgia', code: 'GA'}, {name: 'Hawaii', code: 'HI'}, {name: 'Idaho', code: 'ID'}, {
            name: 'Illinois', code: 'IL'
        }, {name: 'Indiana', code: 'IN'}, {name: 'Iowa', code: 'IA'}, {
            name: 'Kansas', code: 'KS'
        }, {name: 'Kentucky', code: 'KY'}, {name: 'Louisiana', code: 'LA'}, {
            name: 'Maine', code: 'ME'
        }, {name: 'Maryland', code: 'MD'}, {name: 'Massachusetts', code: 'MA'}, {
            name: 'Michigan', code: 'MI'
        }, {name: 'Minnesota', code: 'MN'}, {name: 'Mississippi', code: 'MS'}, {
            name: 'Missouri', code: 'MO'
        }, {name: 'Montana', code: 'MT'}, {name: 'Nebraska', code: 'NE'}, {
            name: 'Nevada', code: 'NV'
        }, {name: 'New Hampshire', code: 'NH'}, {name: 'New Jersey', code: 'NJ'}, {
            name: 'New Mexico', code: 'NM'
        }, {name: 'New York', code: 'NY'}, {name: 'North Carolina', code: 'NC'}, {
            name: 'North Dakota', code: 'ND'
        }, {name: 'Ohio', code: 'OH'}, {name: 'Oklahoma', code: 'OK'}, {
            name: 'Oregon', code: 'OR'
        }, {name: 'Pennsylvania', code: 'PA'}, {name: 'Rhode Island', code: 'RI'}, {
            name: 'South Carolina', code: 'SC'
        }, {name: 'South Dakota', code: 'SD'}, {name: 'Tennessee', code: 'TN'}, {
            name: 'Texas', code: 'TX'
        }, {name: 'Utah', code: 'UT'}, {name: 'Vermont', code: 'VT'}, {
            name: 'Virginia', code: 'VA'
        }, {name: 'Washington', code: 'WA'}, {
            name: 'Washington DC', code: 'DC'
        }, {name: 'West Virginia', code: 'WV'}, {
            name: 'Wisconsin', code: 'WI'
        }, {name: 'Wyoming', code: 'WY'}, {name: 'Puerto Rico', code: 'PR'},],
        mxStates: [{name: "CP - (Chis.)-Chiapas", code: "CP"}, {
            name: "GJ - (Gto.)-Guanajuato", code: "GJ"
        }, {name: "CH - (Chih.)-Chihuahua", code: "CH"}, {
            name: "ZA - (Zac.)-Zacatecas", code: "ZA"
        }, {name: "MR - (Mor.)-Morelos", code: "MR"}, {
            name: "OA - (Oax.)-Oaxaca", code: "OA"
        }, {name: "NL - (N.L.)-NuevoLeon", code: "NL"}, {
            name: "YU - (Yuc.)-Yucatan", code: "YU"
        }, {name: "GR - (Gro.)-Guerrero", code: "GR"}, {
            name: "CM - (Camp.)-Campeche", code: "CM"
        }, {name: "SI - (Sin.)-Sinaloa", code: "SI"}, {
            name: "VE - (Ver.)-Veracruz", code: "VE"
        }, {name: "TB - (Tab.)-Tabasco", code: "TB"}, {
            name: "AG - (Ags.)-Aguascalientes", code: "AG"
        }, {name: "HI - (Hgo.)-Hidalgo", code: "HI"}, {
            name: "NA - (Nay.)-Nayarit", code: "NA"
        }, {name: "MC - (Mich.)-Michoacan", code: "MC"}, {
            name: "SO - (Son.)-Sonora", code: "SO"
        }, {name: "TM - (Tamps.)-Tamaulipas", code: "TM"}, {
            name: "PU - (Pue.)-Puebla", code: "PU"
        }, {name: "CL - (Coah.)-Colima", code: "CL"}, {
            name: "TL - (Tlax.)-Tlaxcala", code: "TL"
        }, {name: "DU - (Dgo.)-Durango", code: "DU"}, {
            name: "JA - (Jal.)-Jalisco", code: "JA"
        }, {name: "BN - (B.C.)-Baja California", code: "BN"}, {
            name: "MX - (Edomex. or Méx.)-Estadode Mexico", code: "MX"
        }, {name: "QR - (Q. Roo. or Q.R.)-Quintana Roo", code: "QR"}, {
            name: "QE - (Qro.)-Queretaro de Arteaga", code: "QE"
        }, {name: "BS - (B.C.S.)-Baja California Sur", code: "BS"}, {
            name: "CA - (Chih.)-Coahuila de Zaragoza", code: "CA"
        }, {name: "DF - (CDMX)-Distrito Federal", code: "DF"}, {
            name: "SL - (S.L.P.)-San Luis Potos", code: "SL"
        }],
        mxIdTypes: [{name: 'Credencial para votar', code: 'VoterID'}, {
            name: 'Pasaporte vigente', code: 'Passport'
        }, {name: 'Licencia para conducir vigente', code: 'DriverLicense'}, {
            name: 'Comprobante Fiscal Digital', code: 'CFDITaxID'
        }, {name: 'Documento migratorio', code: 'ImmigrationDocument'}, {
            name: 'Credencial del Instituto Nacional', code: 'SeniorCitizenID'
        }, {name: 'Cédula profesiona', code: 'ProfessionalCertificate'},],
        connections: ['Wireless', 'Phone', 'Satellite', 'DSL', 'Air card or Hotspot', 'Local Internet provider', 'Cable or Fiber', 'No internet',],
        connectionsMX: ['Sin internet', 'Internet por cable o Internet por fibra', 'DSL', 'Wireless', 'El Telefono Celular', 'Internet satelital'],
        successfulPimCodes: ['565','681','682','994','3004','3005','3008','3009','3011','3012','3014','3015','3017','3019','3020','3021','3022','3039','3040','3058','3059','3060','3061','3094','3095','3096','3099','3100','3101','3102','3148','9990'],
        availableInstallDates: null,
        NLADPrograms: ['E1 – Medicaid', 'E2 – Supplemental Nutrition Assistance Program (SNAP)', 'E3 – Supplemental Security', 'E4 – Federal Public Housing Assistance', 'E8 – Bureau of Indian Affairs General Assistance', 'E9 – Tribal Temporary Assistance for Needy Families (Tribal TANF)', 'E10 – Food Distribution Program on Indian Reservations (FDPIR)', 'E11 – Head Start', 'E13 – Eligibility Based on Income', 'E15 – Veterans Pension or Survivors Pension', 'E50 – School Lunch/Breakfast Program', 'E51 – Federal Pell Grant',],
        tvServiceTypes: ['DISH', 'DTV', 'Cable TV', 'Streaming only', 'No service',],
        isAgentNoteOpen: true,
        isCurrentUserAnAgent: true,
        notesSection: '',
        notes: [],
        selectedNote: null,
        ssoToken: '',
        simpleToken: '',
        username: '',
        password: '',
        showLoginForm: false,
        unAuthorizedMessage: '',
        loginState: '',
        loginError: '',
        mxNoServicePackages: ['MX Offer_01L_JU_01', 'MX Offer_01P_JU_01'],
        useNewAPI: true,
        dish_returned: false,
        nybb_returned: false,
        nonPCI: false,
        hideScriptButtons: false,
    }, reducers: {
        setLang: (state, action) => {
            state.lang = action.payload
            state.labels = getLabelBasedOnEnglish(state)
        }, detectLang: (state, action) => {
            const map = {
                'english': 'en',
                'english-dealer': 'en',
                'consumer_pr1': 'es',
                'spanish': 'es',
                'spanish-mx': 'es-mx',
                'en-external': 'en-external',
            }
            let {language, program} = action.payload
            if (!language) {
                language = 'english'
            }
            if (isMexico(program)) {
                language = 'spanish-mx'
            }
            if (program === programNonPCI) {
                language = 'en-external'
            }


            state.lang = map[language]
            state.labels = getLabelBasedOnEnglish(state)
        }, setLangScript: (state, action) => {
            const script = action.payload
            const {lang, labelsByLang, labels} = state
            for (const labelsKey in labels) {
                const target = labelsByLang[lang] ?? {}
                if (target.hasOwnProperty(labelsKey) && target[labelsKey].length > 1) {
                    const item = target[labelsKey].find(i => isSameScriptType(i.script, script))
                    if (item) {
                        labels[labelsKey] = item.translation
                    }
                }
            }
            state.labels = {...labels}
        }, setOrderType: (state, action) => {
            state.orderType = action.payload
        }, setIsAgentNoteOpen: (state, action) => {
            state.isAgentNoteOpen = action.payload
        }, setNotesSection: (state, action) => {
            const {section, script, mediaType} = action.payload
            state.notesSection = section
            let noteScript = script
            if (isDealer(mediaType)) {
                noteScript = 'Dealer'
            }
            if (state.notes) {
                let note = state.notes.find(item => item.name === section && isSameScriptType(item.script, noteScript))
                if (!note) {
                    note = state.notes.find(item => item.name === section)
                }
                state.selectedNote = note
            }
        }, setSSOToken: (state, action) => {
            state.ssoToken = action.payload
        }, setSimpleToken: (state, action) => {
            state.simpleToken = action.payload
        }, setShowLoginForm: (state, action) => {
            state.showLoginForm = action.payload
        }, setAppLoadingState: (state, action) => {
            state.appLoadingState = action.payload
        }, setNonPCI: (state, action) => {
            state.nonPCI = action.payload
        },
        hideScriptButtons: (state) => {
            state.hideScriptButtons = true;
        }
    }, extraReducers: (builder) => {
        const setGetAvailableInstallDates = () => {
            const {fulfilled} = getAvailableInstallDates
            builder.addCase(fulfilled, (state, action) => {
                state.availableInstallDates = Object.values(action.payload)
            })
        }

        const setGetStringTranslation = () => {
            const {pending, rejected, fulfilled} = getStringTranslations
            builder.addCase(pending, state => {
                state.appLoadingState = 'pending'
            })
            builder.addCase(rejected, state => {
                state.appLoadingState = ''
            })
            builder.addCase(fulfilled, (state, action) => {
                state.appLoadingState = ''
                const labelsByLang = {}
                for (const item of action.payload) {
                    if (!labelsByLang.hasOwnProperty(item.lang)) {
                        labelsByLang[item.lang] = {}
                    }
                    if (!labelsByLang[item.lang].hasOwnProperty(item.name)) {
                        labelsByLang[item.lang][item.name] = [item]
                    }
                    labelsByLang[item.lang][item.name].push(item)
                }
                state.labelsByLang = labelsByLang
                state.labels = getLabelBasedOnEnglish(state)
            })
        }

        const setGetSectionNotes = () => {
            const {fulfilled} = getSectionNotes
            builder.addCase(fulfilled, (state, action) => {
                state.notes = action.payload

            })
        }

        const setLogin = () => {
            const {fulfilled, pending} = login
            builder.addCase(fulfilled, (state, action) => {
                const [ok, username, password] = action.payload
                state.loginState = ''
                if (ok) {
                    state.loginError = ''
                    state.username = username
                    state.password = password
                    state.showLoginForm = false
                } else {
                    state.loginError = 'Invalid credentials'
                }
            })
            builder.addCase(pending, (state) => {
                state.loginState = 'pending'
            })
        }

        setGetAvailableInstallDates()
        setGetStringTranslation()
        setGetSectionNotes()
        setLogin()
    },
})


export const {
    setLang,
    setOrderType,
    setIsAgentNoteOpen,
    setNotesSection,
    setSimpleToken,
    setSSOToken,
    setShowLoginForm,
    setAppLoadingState,
    setLangScript,
    detectLang,
    setNonPCI,
    hideScriptButtons,
} = settingsSlice.actions

export default settingsSlice.reducer
