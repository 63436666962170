import {useDispatch, useSelector} from 'react-redux'
import {isExternal, isMexico, useInputDataState, useIsPR, useLabels, useNybbReturned} from '../../functions'
import {setDataState, setDishSubscriber} from "../../state/customerSlice";
import {
    changePriorityPackage,
    showNycafPackage,
    showRegularPackage,
    writeExtraChoices,
    writeToRedis
} from '../../state/customerAsyncActions'
import {Col, Form, Row} from 'react-bootstrap'
import {HowManyUsers} from './general'
import {FormCheck, FormLabel} from '../Utilities/Form'
import DishBundle from "../DishBundle";
import EligibleCustomer from "../DishBundle/EligibleCustomer";
import {setAppLoadingState} from "../../state/settingsSlice";

export const Features = () => {
    const labels = useLabels()
    const [satPackageChoice, setSatPackageChoice] = useInputDataState('satPackageChoice')
    const [useHomeBusinessChoice, setUseHomeBusinessChoice] = useInputDataState('useHomeBusinessChoice')
    const program = useSelector(state => state.customer.data.program)
    const [someonePlayVideoGames, setSomeonePlayVideoGames] = useInputDataState('someonePlayVideoGames')
    const tvServiceTypesOptions = useSelector(state => state.settings.tvServiceTypes)
    const [tvServiceType, setTvServiceType] = useInputDataState('tvServiceType')
    const [dishSubscriberHasNumber, setDishSubscriberHasNumber] = useInputDataState('dishSubscriberHasNumber')
    const [dishBundleMentioned, setDishBundleMentioned] = useInputDataState('dishBundleMentioned')
    const [planToKeepTV, setPlanToKeepTV] = useInputDataState('planToKeepTV')
    const [wishToHearDish, setWishToHearDish] = useInputDataState('wishToHearDish')
    const [workFromHome, setWorkFromHome] = useInputDataState('workFromHome')
    const [vpnUsed, setVpnUsed] = useInputDataState('vpnUsed')
    const [securityUsed, setSecurityUsed] = useInputDataState('securityUsed')
    const [userWantsNycaf, setUserWantsNycaf] = useInputDataState('userWantsNycaf')
    const [isCallerSubscribedToDish, setIsCallerSubscribedToDish] = useInputDataState('isCallerSubscribedToDish')
    const mediaType = useSelector(state => state.customer.data.mediaType)
    const dispatch = useDispatch()
    const isPR1 = useIsPR()
    const nybb_returned = useNybbReturned()

    const onSatPackageChoice = (e) => {
        setSatPackageChoice(e)
        dispatch(writeExtraChoices())
    }

    const handleMentioned = async (e) => {
        dispatch(setAppLoadingState('pending'))
        dispatch(setDataState({key: 'dishBundleAccepted', value: e.target.value}))
        setDishBundleMentioned(e)
        await dispatch(writeToRedis())
        await dispatch(changePriorityPackage())
        dispatch(setAppLoadingState(''))
    }

    const handleHasNumber = async (e) => {
        dispatch(setAppLoadingState('pending'))
        dispatch(setDataState({key: 'dishBundleAccepted', value: e.target.value}))
        setDishSubscriberHasNumber(e)
        await dispatch(writeToRedis())
        await dispatch(changePriorityPackage())
        dispatch(setAppLoadingState(''))
    }


    const handleWantsNycaf = async (e) => {
        dispatch(setAppLoadingState('pending'))
        dispatch(setDataState({key: 'userWantsNycaf', value: e.target.value}))
        await dispatch(writeToRedis())

        if (e.target.value === 'Y') {
            await dispatch(showNycafPackage())
        } else {
            await dispatch(showRegularPackage())
        }

        dispatch(setAppLoadingState(''))
    }

    // const handleTvServiceTypeChange = (e) => {
    //     dispatch(setTvServiceType(e.target.value));
    // };

    const onClickIsCallerSubscribedToDish = (e) => {
        setIsCallerSubscribedToDish(e)
        let accountNumber = '8255909216348059';
        let lastName = 'Ahdoot';
        if (e.target.value === 'No') {
            accountNumber = ''
            lastName = ''
        }
        dispatch(setDishSubscriber({accountNumber}))
        dispatch(setDishSubscriber({lastName}))
    }

    let packageChoicesComponent
    let packageDishBundleMentionedComponent
    let packageWhatTypeTVServiceComponent
    let packageOnlineVideoGamesComponent
    let packageWorkFromHomeComponent
    let packageSecurityUsedComponent
    let useHomeBusinessQuestionComponent
    let userWantsNycafComponent

    let planToKeepTVComponent
    let wishToHearDishComponent
    if (['DISH'].includes(tvServiceType)) {
        planToKeepTVComponent = <Col md={6}>
            <FormLabel required={true}>{labels.planToKeepTVQuestion}</FormLabel>
            <FormCheck type="radio" name="planToKeepTV" value="Yes" label="Yes" onChange={setPlanToKeepTV}
                       checked={planToKeepTV === 'Yes'}/>
            <FormCheck type="radio" name="planToKeepTV" value="No" label="No" onChange={setPlanToKeepTV}
                       checked={planToKeepTV === 'No'}/>
        </Col>
    }

    if ([ 'DTV', 'Cable TV', 'Streaming only', 'No service'].includes(tvServiceType) && !isExternal(program)){
        wishToHearDishComponent = <Col md={6}>
            <FormLabel required={true}>{labels.wishToHearDishQuestion}</FormLabel>
            <FormCheck type="radio" name="wishToHearDish" value="Yes" label="Yes" onChange={setWishToHearDish}
                       checked={wishToHearDish === 'Yes'}/>
            <FormCheck type="radio" name="wishToHearDish" value="No" label="No" onChange={setWishToHearDish}
                       checked={wishToHearDish === 'No'}/>
        </Col>

    }

    let dishSubscriberHasNumberComponent
    dishSubscriberHasNumberComponent = <Col md={6}>
        <FormLabel required={true}>{labels.dishSubscriberHasNumberQuestion}</FormLabel>
        <FormCheck type="radio" name="dishSubscriberHasNumber" value="Yes" label="Yes"
                   onChange={handleHasNumber}
                   checked={dishSubscriberHasNumber === 'Yes'}/>
        <FormCheck type="radio" name="dishSubscriberHasNumber" value="No" label="No"
                   onChange={handleHasNumber}
                   checked={dishSubscriberHasNumber === 'No'}/>
    </Col>

    let eligibleCustomerComponent
    if (['DISH'].includes(tvServiceType) && mediaType !== 'Campaigns' && !isExternal(program)) {
        eligibleCustomerComponent = <>
            {dishSubscriberHasNumberComponent}
            <Row className="mb-3">
                <EligibleCustomer md={6}/>
            </Row>
        </>

    }

    let vpnUsedComponent
    if (workFromHome === 'Y' && !isPR1) {
        vpnUsedComponent = <Col md={6}>
            <FormLabel required={true}>{labels.vpnUsedQuestion}</FormLabel>
            <FormCheck type="radio" label={labels.yesOption} value="Y" checked={vpnUsed === 'Y'}
                       onChange={setVpnUsed}/>
            <FormCheck type="radio" label="No" value="N" checked={vpnUsed === 'N'}
                       onChange={setVpnUsed}/>
        </Col>
    }

    if (isMexico(program)) {
        useHomeBusinessQuestionComponent = <Row className="mb-3">
            <Col>
                <FormLabel bold={true}>{labels.useHomeBusinessQuestion}</FormLabel>
                <FormCheck type="radio" name="useHomeBusinessChoice" checked={useHomeBusinessChoice === 'Home'}
                           label='Casa' value='Home' onChange={setUseHomeBusinessChoice}/>
                <FormCheck type="radio" name="useHomeBusinessChoice" checked={useHomeBusinessChoice === 'Office'}
                           label='Oficina (Pyme o emprendimiento)' value='Office' onChange={setUseHomeBusinessChoice}/>
            </Col>
        </Row>
    } else if (isPR1) {
        packageChoicesComponent = <Row className="mb-3">
            <Col>
                <FormLabel bold={true}>{labels.satPackageChoice}</FormLabel>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend1And2}
                           label={labels.recommend1And2} value={labels.recommend1And2} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend3And4}
                           label={labels.recommend3And4} value={labels.recommend3And4} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend5And10}
                           label={labels.recommend5And10} value={labels.recommend5And10} onChange={onSatPackageChoice}/>
                <FormCheck type="radio" name="satPackageChoice" checked={satPackageChoice === labels.recommend11And15}
                           label={labels.recommend11And15} value={labels.recommend11And15}
                           onChange={onSatPackageChoice}/>
            </Col>
        </Row>
    } else if (!isMexico(program)) {

        if (!isPR1) {
            packageWhatTypeTVServiceComponent = <Row className="mb-3">
                <Col md={6}>
                    <FormLabel required={true}>{labels.whatTypeTVService}</FormLabel>
                    <Form.Select value={tvServiceType} onChange={setTvServiceType}>
                        <option></option>
                        {tvServiceTypesOptions.map(item => <option key={item} value={item}>{item}</option>)}
                    </Form.Select>
                </Col>
                {planToKeepTVComponent}
                {wishToHearDishComponent}
                {eligibleCustomerComponent}
            </Row>

            if (!isExternal(program)) {
                packageDishBundleMentionedComponent = <>
                    <Row className="mb-3">
                        <Col md={6}>
                            <FormLabel required={true}>{labels.dishBundleMentionedQuestion}</FormLabel>
                            <FormCheck type="radio" label={labels.yesOption} value="Yes"
                                       checked={dishBundleMentioned === 'Yes'}
                                       onChange={handleMentioned}/>
                            <FormCheck type="radio" label="No" value="No" checked={dishBundleMentioned === 'No'}
                                       onChange={handleMentioned}/>
                        </Col>
                        {/*<Col md={6}>*/}
                        {/*    <FormLabel>{labels.isCallerSubscribedToDish}</FormLabel>*/}
                        {/*    <FormCheck type="radio" label="Yes" value="Yes" checked={isCallerSubscribedToDish === 'Yes'}*/}
                        {/*               onChange={onClickIsCallerSubscribedToDish}/>*/}
                        {/*    <FormCheck type="radio" label="No" value="No" checked={isCallerSubscribedToDish === 'No'}*/}
                        {/*               onChange={onClickIsCallerSubscribedToDish}/>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row className="mb-3">
                        <DishBundle md={6}/>
                    </Row>
                </>
            }

            packageWorkFromHomeComponent = <Row className="mb-3">
                <Col md={6}>
                    <FormLabel required={true}>{labels.workFromHomeQuestion}</FormLabel>
                    <FormCheck type="radio" label={labels.yesOption} value="Y" checked={workFromHome === 'Y'}
                               onChange={setWorkFromHome}/>
                    <FormCheck type="radio" label="No" value="N" checked={workFromHome === 'N'}
                               onChange={setWorkFromHome}/>
                </Col>
                {vpnUsedComponent}
            </Row>

            packageSecurityUsedComponent = <Row className="mb-3">
                <Col md={6}>
                    <FormLabel required={true}>{labels.securityUsedQuestion}</FormLabel>
                    <FormCheck type="radio" label={labels.yesOption} value="Y" checked={securityUsed === 'Y'}
                               onChange={setSecurityUsed}/>
                    <FormCheck type="radio" label="No" value="N" checked={securityUsed === 'N'}
                               onChange={setSecurityUsed}/>
                </Col>
            </Row>

            if (nybb_returned) {
                userWantsNycafComponent = <Row className="mb-3">
                    <Col md={6}>
                        <FormLabel required={true}>{labels.userWantsNycafQuestion}</FormLabel>
                        <FormCheck type="radio" label={labels.yesOption} value="Y" checked={userWantsNycaf === 'Y'}
                                   onChange={handleWantsNycaf}/>
                        <FormCheck type="radio" label="No" value="N" checked={userWantsNycaf === 'N'}
                                   onChange={handleWantsNycaf}/>
                    </Col>
                </Row>
            }
        }
    }


    packageOnlineVideoGamesComponent = <Col md={6}>
        <Form.Group>
            <FormLabel bold={true}>{labels.onlineVideoGameQuestion}</FormLabel>
            <FormCheck type="radio" label={labels.yesOption} value="Y" checked={someonePlayVideoGames === 'Y'}
                       onChange={setSomeonePlayVideoGames}/>
            <FormCheck type="radio" label="No" value="N" checked={someonePlayVideoGames === 'N'}
                       onChange={setSomeonePlayVideoGames}/>
        </Form.Group>
    </Col>


    return <>

        {packageChoicesComponent}

        <Row className="mb-3">
            <HowManyUsers md={6}/>
        </Row>
        {packageDishBundleMentionedComponent}
        {packageWhatTypeTVServiceComponent}
        {packageOnlineVideoGamesComponent}
        {useHomeBusinessQuestionComponent}
        {packageWorkFromHomeComponent}
        {packageSecurityUsedComponent}
        {userWantsNycafComponent}


    </>
}

export default Features
