import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import validate from 'validate.js'
import {urlHost} from '../constants'
import {isBusiness, isMexico} from '../functions'
import {updateCustomerPhoneNumber, updateTcPhone} from "./customerSlice";


const addressConstraints = {
    firstName: {
        presence: {
            allowEmpty: false
        }
    },
    lastName: {
        presence: {
            allowEmpty: false
        },
        length: {
            minimum: 2
        }
    },
    address: {
        presence: {
            allowEmpty: false
        }
    },
    city: {
        presence: {
            allowEmpty: false
        }
    },
    state: {
        presence: {
            allowEmpty: false
        }
    },
    zip: {
        presence: {
            allowEmpty: false
        }
    },
}

function resetObject(obj, val) {
    for (const objKey in obj) {
        obj[objKey] = val
    }
}

export const validateServiceAddress = createAsyncThunk('validation/serviceAddress', async (arg, thunkAPI) => {
    //const {serviceAddress, script, caseId, program} = thunkAPI.getState().customer.data

    const {script, caseId, program} = thunkAPI.getState().customer.data
    let {serviceAddress} = thunkAPI.getState().customer.data;
    serviceAddress = {
        ...serviceAddress,
        phoneNumber: serviceAddress.phoneNumber.replace(/-/g, '')
    }
    thunkAPI.dispatch(updateCustomerPhoneNumber(serviceAddress.phoneNumber));


    let constraints = {...addressConstraints, phoneNumber: {presence: true, length: {minimum: 10}}}

    if (isMexico(program)) {
        constraints.zip = {
            presence: true,
            format: {
                pattern: /^\d{5}$/
               // message: 'must be a 5-digit number'
            }
        };
    }

    if (isBusiness(script) && !isMexico(program)) {
        constraints.companyName = {
            presence: {
                allowEmpty: false
            }
        }
    }
    let validation = validate(serviceAddress, constraints)
    if (!validation && !isMexico(program)) {
        const body = JSON.stringify({
            caseid: caseId,
            checkphone: serviceAddress.phoneNumber,
            checkaddress: serviceAddress.address
        })
        const headers = {'Content-Type': 'application/json'}
        let response = await fetch(`${urlHost}/srv_calldata/forbidden_phone.php`, {
            method: 'post',
            body,
            headers
        })

        if (response.ok) {
            if (await response.json()) {
                validation = {phoneNumber: 'Phone number is forbidden. Use another number'}
            }
        }

        response = await fetch(`${urlHost}/srv_calldata/forbidden_phone_previously_used.php`)

        if (response.ok) {
            if (await response.json()) {
                validation = {phoneNumber: 'Phone has been used before'}
            }
        }

    }
    return validation
})

export const validateBillingAddress = createAsyncThunk('validation/billingAddress', (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
        try {
            const {billingAddress} = thunkAPI.getState().customer.data
            resolve(validate(billingAddress, addressConstraints))
        } catch (e) {
            reject(e)
        }
    })
})


export const validatePreviewOrder = createAsyncThunk('validation/previewOrder', async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
        try {
            let ret
            const {tcEmail, tcPhone} = thunkAPI.getState().customer.data
            if (!tcEmail && !tcPhone) {
                ret = {
                    tcEmail: 'Please input an email or phone to send the preview Order',
                    tcPhone: 'Please input an email or phone to send the preview Order',
                }
            }

            if (tcEmail && validate({tcEmail}, {tcEmail: {email: true}}) !== undefined) {
                ret = {tcEmail: 'Invalid email address'}
            }

            if (tcPhone) {
                let {tcPhone} = thunkAPI.getState().customer.data
                tcPhone = tcPhone.replace(/-/g, '')
                thunkAPI.dispatch(updateTcPhone(tcPhone))
            }

            resolve(ret)
        } catch (e) {
            reject(e)
        }
    })
})

export const validateDishSubscriber = createAsyncThunk('validation/dishSubscriber', async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
        const presence = {
            presence: {
                allowEmpty: false
            }
        }
        const constraints = {
            accountNumber: presence,
            lastName: presence,
        }
        const {dishSubscriber} = thunkAPI.getState().customer.data

        try {
            resolve(validate(dishSubscriber, constraints))
        } catch (e) {
            reject(e)
        }
    })
})
export const validateBroadbandFacts = createAsyncThunk('validation/broadbandFacts', async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
        try {
            let ret
            const {tcEmail, tcPhone} = thunkAPI.getState().customer.data
            if (!tcEmail && !tcPhone) {
                ret = {
                    tcEmail: 'Please input an email or phone to send the Broadband Facts',
                    tcPhone: 'Please input an email or phone to send the Broadband Facts',
                }
            }

            if (tcEmail && validate({tcEmail}, {tcEmail: {email: true}}) !== undefined) {
                ret = {tcEmail: 'Invalid email address'}
            }

            if (tcPhone) {
                let {tcPhone} = thunkAPI.getState().customer.data
                tcPhone = tcPhone.replace(/-/g, '')
                thunkAPI.dispatch(updateTcPhone(tcPhone))
            }

            resolve(ret)
        } catch (e) {
            reject(e)
        }
    })
})
export const validateCreditCard = createAsyncThunk('validation/creditCard', async (arg, thunkAPI) => {
    return new Promise((resolve, reject) => {
        const presence = {
            presence: {
                allowEmpty: false
            }
        }
        const constraints = {
            paymentType: presence,
            creditNumber: presence,
            expireMonth: presence,
            expireYear: presence,
            cvv: presence,
        }
        const {creditCard} = thunkAPI.getState().customer.data

        try {
            resolve(validate(creditCard, constraints))
        } catch (e) {
            reject(e)
        }
    })
})

export const validateHowDidYouHear = createAsyncThunk('validation/HowDidYouHear', async (arg, thunkAPI) => {
    return new Promise((resolve) => {
        const state = thunkAPI.getState()
        const {howDidYouHear} = state.customer.data
        if (!howDidYouHear) {
            resolve('Required')
        }

        resolve(null)
    })
})


export const validationSlice = createSlice({
    name: 'validation',
    initialState: {
        serviceAddress: {
            firstName: null,
            lastName: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            country: null,
            zip: null,
            phoneNumber: null,
            overrideAddress: null,
            companyName: null,
        },
        billingAddress: {
            firstName: null,
            lastName: null,
            address: null,
            address2: null,
            city: null,
            state: null,
            country: null,
            zip: null,
            phoneNumber: null,
            overrideAddress: null,
        },
        previewOrder: {
            tcEmail: null,
            tcPhone: null,
        },
        creditCard: {
            paymentType: null,
            creditNumber: null,
            expireMonth: null,
            expireYear: null,
            cvv: null,
        },
        dishSubscriber: {
            accountNumber: null,
            lastName: null,
        },
        howDidYouHear: null,
    },
    reducers: {},
    extraReducers: (builder) => {

        const setValidateServiceAddress = () => {
            const {fulfilled, pending} = validateServiceAddress
            builder.addCase(pending, (state) => {
                resetObject(state.serviceAddress, null)
            })
            builder.addCase(fulfilled, (state, action) => {
                if (action.payload) {
                    state.serviceAddress = {...state.serviceAddress, ...action.payload}
                }
            })
        }

        const setValidateBillingAddress = () => {
            const {fulfilled, pending} = validateBillingAddress
            builder.addCase(pending, (state) => {
                resetObject(state.billingAddress, null)
            })
            builder.addCase(fulfilled, (state, action) => {
                if (action.payload) {
                    state.billingAddress = {...state.billingAddress, ...action.payload}
                }
            })
        }

        const setValidatePreviewOrder = () => {
            const {fulfilled} = validatePreviewOrder
            builder.addCase(fulfilled, (state, action) => {
                resetObject(state.previewOrder, null)
                if (action.payload) {
                    state.previewOrder = {...state.previewOrder, ...action.payload}
                }
            })
        }

        const setValidateCreditCard = () => {
            const {fulfilled} = validateCreditCard
            builder.addCase(fulfilled, (state, action) => {
                if (action.payload) {
                    state.creditCard = {...state.creditCard, ...action.payload}
                }
            })
        }

        const setValidateDishSubscriber = () => {
            const {fulfilled} = validateDishSubscriber
            builder.addCase(fulfilled, (state, action) => {
                if (action.payload) {
                    state.dishSubscriber = {...state.dishSubscriber, ...action.payload}
                }
            })
        }


        const setHowDidYouHear = () => {
            const {fulfilled} = validateHowDidYouHear
            builder.addCase(fulfilled, (state, action) => {
                state.howDidYouHear = action.payload
            })
        }


        setValidateServiceAddress()
        setValidateBillingAddress()
        setValidatePreviewOrder()
        setValidateCreditCard()
        setValidateDishSubscriber()
        setHowDidYouHear()


    }
})


export default validationSlice.reducer
