import {useDispatch, useSelector} from 'react-redux'
import {useInputDataState, useLabels} from '../../functions'
import {Button, Col, Form, Row} from 'react-bootstrap'
import {sendBroadbandFacts} from '../../state/customerAsyncActions'
import {validateBroadbandFacts} from '../../state/validationSlice'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {useEffect} from 'react'
import {openAlert} from '../../state/customerSlice'
import {Email} from "../Utilities/GeneralComponents";


export const BroadbandFacts = () => {
    const labels = useLabels()
    const sendBroadbandFactsState = useSelector(state => state.customer.sendBroadbandFactsState)
    const [tcPhone, setTCPhoneNumber] = useInputDataState('tcPhone')
    const phoneNumber = useSelector(state => state.customer.data.serviceAddress.phoneNumber)
    const epqPass = useSelector(state => state.customer.data.epqPass)
    const tcEmail = useSelector(state => state.customer.data.tcEmail)
    const dispatch = useDispatch()
    const dishBundleAccepted = useSelector(state => state.customer.data.dishBundleAccepted)
    const [contactConsentBroadband, setContactConsentBroadband] = useInputDataState('contactConsentBroadband')
    const [reviewedLabel, setReviewedLabel] = useInputDataState('reviewedLabel')
    const [readLabel, setReadLabel] = useInputDataState('readLabel')
    const [customerDeclinedLabel, setCustomerDeclinedLabel] = useInputDataState('customerDeclinedLabel')

    const onClick = async () => {
        const ret = await dispatch(validateBroadbandFacts())
        if (ret.payload !== null) {
            if (tcEmail && tcEmail !== 'nobody@hughes.com' && tcEmail.substring(0, 2) === 'no') {
                dispatch(openAlert(labels.invalidEmailAddress))
                return
            }
            dispatch(sendBroadbandFacts())
        }
    }

    useEffect(() => {
        if (!tcPhone && phoneNumber) {
            setTCPhoneNumber({target: {value: phoneNumber}})
        }
    }, [])


    let contactConsentBroadbandComponent
    contactConsentBroadbandComponent = <Row className="mt-3 mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true} required={true}>{labels.consentToContactBroadband}</FormLabel>
                <FormCheck label={labels.yesOption} type="radio" value="Y" onChange={setContactConsentBroadband}
                           checked={contactConsentBroadband === 'Y'}/>
                <FormCheck label="No" type="radio" value="N" onChange={setContactConsentBroadband}
                           checked={contactConsentBroadband === 'N'}/>
            </Form.Group>
        </Col>
    </Row>


    let EmailPhoneNumberComponent
    if (contactConsentBroadband === 'Y') {
        EmailPhoneNumberComponent = <Row className="mt-3">
            <Email md={5}/>
            <Col md={5}>
                <FormLabel bold={true}>{labels.addressPhoneNumber}</FormLabel>
                <Form.Control value={tcPhone} onChange={setTCPhoneNumber}/>
            </Col>
        </Row>

    }
    let LinkComponent
    if (contactConsentBroadband === 'Y' && dishBundleAccepted === 'Yes') {
        LinkComponent = <Row>
            <Col>
                <a href="https://hughesnet.com/DISHLabels" target="_blank">{labels.sendBroadbandFactsLink}</a>

            </Col>
        </Row>
    } else {
        LinkComponent = <Row>
            <Col>
                <a href="https://labels.hughesnet.com" target="_blank">{labels.sendBroadbandFactsLink}</a>

            </Col>
        </Row>
    }

    let ButtonComponent
    if (contactConsentBroadband === 'Y') {
        ButtonComponent = <Row className="mt-3 mb-3">
            <Col md={{span: 4, offset: 5}}>
                <Button onClick={onClick} disabled={epqPass !== '2'}>
                    {sendBroadbandFactsState !== 'pending' ? labels.sendBroadbandFacts : labels.sendingBroadbandFacts}
                </Button>
            </Col>
        </Row>

    }


    let reviewedLabelComponent
    reviewedLabelComponent = <Row className="mt-3 mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true} required={true}>{labels.reviewedLabelComponent}</FormLabel>
                <FormCheck label={labels.yesOption} type="radio" value="Y" onChange={setReviewedLabel}
                           checked={reviewedLabel === 'Y'}/>
                <FormCheck label="No" type="radio" value="N" onChange={setReviewedLabel}
                           checked={reviewedLabel === 'N'}/>
            </Form.Group>
        </Col>
    </Row>

    let readLabelComponent
    readLabelComponent = <Row className="mt-3 mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true} required={true}>{labels.readLabelComponent}</FormLabel>
                <FormCheck label={labels.yesOption} type="radio" value="Y" onChange={setReadLabel}
                           checked={readLabel === 'Y'}/>
                <FormCheck label="No" type="radio" value="N" onChange={setReadLabel}
                           checked={readLabel === 'N'}/>
            </Form.Group>
        </Col>
    </Row>

    let customerDeclinedLabelComponent
    customerDeclinedLabelComponent = <Row className="mt-3 mb-3">
        <Col>
            <Form.Group>
                <FormLabel bold={true} required={true}>{labels.customerDeclinedLabelComponent}</FormLabel>
                <FormCheck label={labels.yesOption} type="radio" value="Y" onChange={setCustomerDeclinedLabel}
                           checked={customerDeclinedLabel === 'Y'}/>
                <FormCheck label="No" type="radio" value="N" onChange={setCustomerDeclinedLabel}
                           checked={customerDeclinedLabel === 'N'}/>
            </Form.Group>
        </Col>
    </Row>


    return <>

        {contactConsentBroadbandComponent}
        {EmailPhoneNumberComponent}
        {LinkComponent}
        {ButtonComponent}
        {reviewedLabelComponent}
        {readLabelComponent}
        {customerDeclinedLabelComponent}


    </>

}