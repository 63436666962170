import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {
    fetchCase,
    initBusinessFromConsumer,
    runEPQ,
    sendPersonalInfoEmail,
    setCheckBusinessAddress
} from '../../state/customerAsyncActions'
import {openAlert, setAllowCreditCheck, setBillingAddress} from '../../state/customerSlice'
import {
    isBusiness,
    isDobValid,
    isEPQCodeSuccessful,
    isProblemWithTheAddressPimCode,
    runAndShowLoader,
    useInputDataState,
    useIsPR,
    useIsExternal,
    useLabels
} from '../../functions'
import {validateBillingAddress, validateHowDidYouHear, validateServiceAddress} from '../../state/validationSlice'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {setAppLoadingState} from '../../state/settingsSlice'
import {connect} from '../Notes/wrapper'
import {useState} from 'react'
import {BillingAddressCollection} from '../BillingAddressCollection'
import {Email} from "../Utilities/GeneralComponents";
import {failEPQCode} from "../../constants";

export const EPQButton = () => {
    const labels = useLabels()
    const dispatch = useDispatch()
    const isServiceAndBillingAddressTheSame = useSelector(state => state.customer.data.isServiceAndBillingAddressTheSame)

    const onClick = async () => {
        const ret = await dispatch(validateServiceAddress())
        if (!isServiceAndBillingAddressTheSame) {
            const ret = await dispatch(validateBillingAddress())
            if (ret.payload !== undefined) {
                return
            }
        }
        const howDidYouHearValidation = await dispatch(validateHowDidYouHear())
        if (ret.payload === undefined && howDidYouHearValidation.payload === null) {
            dispatch(setAppLoadingState('pending'))
            await dispatch(runEPQ('1'))
            dispatch(setAppLoadingState(''))
        }
    }
    return (<Row className="mb-3">
        <Col sm={12} md={{span: 4, offset: 4}}>
            <Button onClick={onClick}>{labels.runEPQ}</Button>
        </Col>
    </Row>)
}

export const AllowCreditCheckRaw = () => {
    const labels = useLabels()
    const agreeToCreditCheck = useSelector(state => state.customer.data.agreeToCreditCheck)
    const epqPim = useSelector(state => state.customer.data.epqPim)
    const [dob, setDOB] = useInputDataState('dob')
    const nonPCI = useSelector(state => state.settings.nonPCI)
    const [ssn, setSSN] = useInputDataState('ssn')
    const [acpLearnMore] = useInputDataState('acpLearnMore')
    const showCreditCheck = useSelector(state => state.customer.data.showCreditCheck)
    const isFedexError = useSelector(state => state.customer.data.fedexError)
    const orderPim = useSelector(state => state.customer.data.orderPim)
    const address = useSelector(state => state.customer.data.billingAddress)
    const script = useSelector(state => state.customer.data.script)
    const buildingType = useSelector(state => state.customer.data.buildingType)
    const tvServiceType = useSelector(state => state.customer.data.tvServiceType)
    const securityUsed = useSelector(state => state.customer.data.securityUsed)
    const workFromHome = useSelector(state => state.customer.data.workFromHome)
    const vpnUsed = useSelector(state => state.customer.data.vpnUsed)
    const planToKeepTV = useSelector(state => state.customer.data.planToKeepTV)
    const wishToHearDish = useSelector(state => state.customer.data.wishToHearDish)
    const users = useSelector(state => state.customer.data.users)
    const program = useSelector(state => state.customer.data.program)
    const caseId = useSelector(state => state.customer.data.caseId)
    const dispatch = useDispatch()
    const isPR = useIsPR()
    const dishBundleMentioned = useSelector(state => state.customer.data.dishBundleMentioned)
    const [tcPhone, setTCPhoneNumber] = useInputDataState('tcPhone')
    const sendingPersonalInfoLink = useSelector(state => state.customer.sendingPersonalInfoLink)
    const personalInfoLink = useSelector(state => state.customer.personalInfoLink)
    const isExternal = useIsExternal()

    const onChange = async (e) => {
        dispatch(setAllowCreditCheck(e.target.value === 'Y'))
    }


    const localRunEPQ = () => {
        if (isBusiness(script) && !buildingType) {
            dispatch(openAlert(labels.buildingTypeIsRequired))
            return
        }


        if (!isBusiness(script) && !dishBundleMentioned && !isPR && !isExternal) {
            dispatch(openAlert(labels.dishBundleMentionedRequired))
            return
        }

        if (!isBusiness(script) && !users && !isPR) {
            dispatch(openAlert(labels.howManyUsersInHouseholdRequired))
            return
        }

        if (!isBusiness(script) && !tvServiceType && !isPR) {
            dispatch(openAlert(labels.tvServiceTypeRequired))
            return null
        }

        if (!securityUsed && !isPR) {
            dispatch(openAlert(labels.securityUsedRequired))
            return null
        }

        if (!isBusiness(script) && !workFromHome && !isPR) {
            dispatch(openAlert(labels.workFromHomeRequired))
            return null
        }
        if (!vpnUsed && workFromHome === 'Y' && !isPR) {
            dispatch(openAlert(labels.vpnUsedRequired))
            return null
        }

        if (!planToKeepTV && ['DISH'].includes(tvServiceType) && !isPR) {
            dispatch(openAlert(labels.planToKeepTVRequired))
            return null
        }

        if (!wishToHearDish && ['DTV', 'Cable TV', 'Streaming only', 'No service'].includes(tvServiceType) && !isPR && !isBusiness(script) && !isExternal) {
            dispatch(openAlert(labels.wishToHearDishRequired))
            return null
        }

        if (!isDobValid(dob)) {
            dispatch(openAlert(labels.dobNotValid))
            return null
        }

        runAndShowLoader(async () => {
            await dispatch(runEPQ('2'))
        }, dispatch)
    }

    if (!showCreditCheck) {

        return <>
            <BillingAddressCollection showOverride={false}/>
            <Row className="mb-3">
                <Col md={{span: 3, offset: 5}}>
                    <Button onClick={localRunEPQ}>{labels.searchPlans}</Button>
                </Col>
            </Row>
        </>
    }

    const setAddress = (e) => {
        dispatch(setBillingAddress({overrideAddress: e.target.checked ? 'true' : 'false'}))
    }

    const sendPersonalInfo = async () => {
        await runAndShowLoader(async () => {
            await dispatch(sendPersonalInfoEmail())
        }, dispatch)
    }

    const reloadInfo = () => {
        dispatch(fetchCase({program, caseId}))
    }

    let overrideComponent
    if (isFedexError || isProblemWithTheAddressPimCode(epqPim) || isProblemWithTheAddressPimCode(orderPim)) {
        overrideComponent = (<Row className="mb-3">
            <Col>
                <Form.Group>
                    <FormCheck bold={true} type="checkbox" label={labels.addressOverride}
                               checked={address.overrideAddress === 'true'}
                               onChange={setAddress} name="overrideAddress"/>
                </Form.Group>
            </Col>
        </Row>)
    }

    let buttonLabel = labels.runCreditCheck
    if (!agreeToCreditCheck) {
        buttonLabel = labels.runEPQ2Purchase
    } else if (isProblemWithTheAddressPimCode(epqPim)) {
        buttonLabel = labels.runEPQ2NoCreditCheck
    }

    return (<div>
        <Row className="mt-3 mb-3">
            <Col>
                <Alert>
                    <h3>{labels.creditCheck}</h3>
                    <h5>{labels.creditCheckHelp}</h5>
                </Alert>
            </Col>
        </Row>
        <BillingAddressCollection showOverride={false}/>
        <Row className="mb-3">
            <Col>
                <FormLabel required={true} bold={true}>
                    <span>{labels.allowCreditCheckHelp}</span><br/>
                    {labels.allowCreditCheck}
                </FormLabel>
                <FormCheck type="radio" checked={agreeToCreditCheck} onChange={onChange} value="Y"
                           label={labels.agreeToCreditCheckYes}/>
                <FormCheck type="radio" checked={!agreeToCreditCheck} onChange={onChange} value="N"
                           label={labels.agreeToCreditCheckNo}/>
            </Col>
        </Row>
        {!nonPCI ?
            <Row className="mb-3">
                <Col md={6}>
                    <FormLabel required={acpLearnMore === 'Yes'} bold={true}>{labels.dob}</FormLabel>
                    <Form.Control value={dob} onChange={setDOB}/>
                </Col>
                <Col md={6}>
                    <FormLabel required={acpLearnMore === 'Yes'} bold={true}>{labels.ssn}</FormLabel>
                    <Form.Control value={ssn} onChange={setSSN}/>
                </Col>
            </Row> : null
        }
        {nonPCI && epqPim === failEPQCode ? <>
                <Row>
                    <Email md={6}/>
                    <Col md={5}>
                        <FormLabel bold={true}>{labels.addressPhoneNumber}</FormLabel>
                        <Form.Control value={tcPhone} onChange={setTCPhoneNumber}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        {personalInfoLink ? <Button onClick={reloadInfo}>{labels.refresh}</Button> :
                            <Button disabled={sendingPersonalInfoLink}
                                    onClick={sendPersonalInfo}>{sendingPersonalInfoLink ? 'Sending' : labels.sendSSNAndBirth}</Button>
                        }
                    </Col>
                </Row>
            </>
            : null}
        <Row className="mb-3">
            <Col md={{span: 3, offset: 5}}>
                <Button onClick={localRunEPQ}>
                    {buttonLabel}
                </Button>
            </Col>
        </Row>
        {overrideComponent}
    </div>)
}

export const AllowCreditCheck = connect(AllowCreditCheckRaw, 'credit-check')


export const EPQMessage = () => {
    const isFedexError = useSelector(state => state.customer.data.fedexError)
    let epqMessage = useSelector(state => state.customer.data.epqMessage)
    const state = useSelector(state => ({
        settings: {
            successfulPimCodes: state.settings.successfulPimCodes
        }, customer: {
            data: {
                epqPim: state.customer.data.epqPim
            }
        }
    }))
    const apiError = useSelector(state => state.customer.data.apiError)
    let variant = 'info'
    if (isFedexError) {
        variant = 'warning'
    }
    if (!isEPQCodeSuccessful(state) || apiError) {
        variant = 'danger'
    }
    if (!epqMessage && apiError) {
        epqMessage = apiError
    }

    let component
    if (epqMessage) {
        component = <Alert variant={variant}>{epqMessage}</Alert>
    }
    return <>
        <Row className="mb-3">
            <Col>
                {component}
            </Col>
        </Row>
    </>
}


export const CheckBusinessAddress = () => {
    const labels = useLabels()
    const checkBusinessAddress = useSelector(state => state.customer.checkBusinessAddress)
    const [whyConsumerWasSelected, setWhyConsumerWasSelected] = useInputDataState('whyConsumerWasSelected')
    const [consumerException, setConsumerException] = useInputDataState('consumerException')
    const dispatch = useDispatch()
    const [showWhyConsumerWasSelected, setShowWhyConsumerWasSelected] = useState(false)

    const options = ['Farm or Ranch', 'Apartment Building', 'Mobile Home Park', 'Home Based Business', 'Mixed Use Building or Location', 'REAL BUSINESS ADDRESS', 'Other'];

    const onChange = async (e) => {
        setConsumerException(e)
        if (e.target.value === 'REAL BUSINESS ADDRESS') {
            await dispatch(initBusinessFromConsumer())
            dispatch(setCheckBusinessAddress(false))
        }
        setShowWhyConsumerWasSelected(true)
    }

    const onChangeWhyConsumerWasSelected = (e) => {
        setWhyConsumerWasSelected(e)
    }

    let component
    if (showWhyConsumerWasSelected) {
        component = <>
            <Row>
                <Form.Group>
                    <FormLabel bold={true}>Why consumer was selected</FormLabel>
                    <Form.Control value={whyConsumerWasSelected} onChange={onChangeWhyConsumerWasSelected}/>
                </Form.Group>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Button onClick={() => dispatch(setCheckBusinessAddress(false))}>{labels.save}</Button>
                </Col>
            </Row>
        </>
    }

    return <Modal show={checkBusinessAddress}>
        <Modal.Body>
            <Row>
                <Col>
                    <Form.Group>
                        <FormLabel bold={true}>
                            {labels.installAddressType}
                        </FormLabel>
                        <Form.Select onChange={onChange} value={consumerException}>
                            <option></option>
                            {options.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
            {component}
        </Modal.Body>
    </Modal>
}


export const EPQMessage1 = connect(EPQMessage, 'epq-pim-1')
export const EPQMessage2 = connect(EPQMessage, 'epq-pim-2')
