import {useDispatch, useSelector} from 'react-redux'
import {Alert, Col, Form, Row} from 'react-bootstrap'
import {
    getNote,
    isBusiness,
    isEPQCodeSuccessful,
    useInputDataState,
    useIsMexico,
    useIsPR,
    useLabels
} from '../../functions'
import {FormCheck, FormLabel} from '../Utilities/Form'
import {OptionalServices} from './OptionalServices'
import {connect} from '../Notes/wrapper'
import {
    changePriorityPackage,
    getFlexOfferAvailability,
    getPlansVisualPage,
    writeToRedis
} from '../../state/customerAsyncActions'
import {openAlert, setDataState} from '../../state/customerSlice'
import {BroadbandFacts} from './BroadbandFacts'
import {setAppLoadingState} from "../../state/settingsSlice";

export const Plans = () => {
    const state = useSelector(state => ({
        settings: {
            successfulPimCodes: state.settings.successfulPimCodes
        }, customer: {
            data: {
                epqPim: state.customer.data.epqPim
            }
        }
    }))
    const plans = useSelector(state => state.customer.plans)
    const labels = useLabels()
    const [plan, setPlan] = useInputDataState('plan')
    const epqPass = useSelector(state => state.customer.data.epqPass)
    const lease = useSelector(state => state.customer.data.lease)
    const agreeToCreditCheck = useSelector(state => state.customer.data.agreeToCreditCheck)
    const showCreditCheck = useSelector(state => state.customer.data.showCreditCheck)
    const agentNotes = useSelector(state => state.settings.notes)
    const showPaymentOptions = useSelector(state => state.customer.data.showPaymentOptions)
    const dispatch = useDispatch()
    const [customerRefusedFusion, setCustomerRefuseFusion] = useInputDataState('customerRefusedFusion')
    const fusionPackages = useSelector(state => state.customer.data.fusionPackages)
    const isPR1 = useIsPR()
    const isMexico = useIsMexico()
    const continuity = useSelector(state => state.customer.data.continuity)
    const script = useSelector(state => state.customer.data.script)
    const customerEligibleDishBundle = useSelector(state => state.customer.data.dish_subscriber_verified)
    const [dishBundleAccepted, setDishBundleAccepted] = useInputDataState('dishBundleAccepted')


    const hookSetPlan = (e) => {
        setPlan(e)
        dispatch(getFlexOfferAvailability())
        if (!isMexico) {
            dispatch(openAlert(labels.confirmPlanSelected?.replace('{plan}', e.target.value)))
        }
    }

    let plansComponent

    function isLeaseNotAllowed() {
        return !showPaymentOptions || !showCreditCheck || (showPaymentOptions && showCreditCheck && !agreeToCreditCheck)
    }

    const setLease = (e) => {
        dispatch(setDataState({key: 'lease', value: e.target.value}))
        dispatch(setDataState({key: 'paymentOption', value: e.target.value === 'Y' ? 'L' : 'U'}))
    }

    if (isEPQCodeSuccessful(state) && Array.isArray(plans) && plans.length > 0) {
        let offerComponent
        const note = getNote(agentNotes, 'plan-and-optional-services-offer')
        if (note) {
            offerComponent = <Row>
                <Col>
                    <div dangerouslySetInnerHTML={{__html: note.text}}/>
                </Col>
            </Row>
        }

        const handleDishBundle = async (e) => {
            dispatch(setAppLoadingState('pending'))
            setDishBundleAccepted(e)
            dispatch(writeToRedis())
            await dispatch(changePriorityPackage())
            await dispatch(getPlansVisualPage())
            dispatch(setAppLoadingState(''))
        }


        plansComponent = <>
            {offerComponent}
            <Row>
                <Col>
                    <Alert>
                        <h3>{labels.planSectionHeader}</h3>
                        <h5>{labels.planSectionHeaderHelp}</h5>
                    </Alert>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <FormLabel bold={true}>{labels.pickServicePlan}</FormLabel>
                        {plans.map(p => <FormCheck key={p}
                                                   disabled={!isMexico && epqPass !== '2' && lease === 'Y'}
                                                   type="radio"
                                                   name="plan" value={p}
                                                   label={p} checked={plan === p} onChange={hookSetPlan}/>)}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <Form.Group>
                        <FormLabel bold={true}>{labels.equipmentOption}</FormLabel>
                        <FormCheck disabled={isLeaseNotAllowed()} label={labels.leaseOption} value="Y"
                                   checked={lease === 'Y'}
                                   name="lease" onChange={setLease}/>
                        {!isMexico ?
                            <FormCheck label={labels.purchaseOption} value="N" checked={lease === 'N'} name="lease"
                                       onChange={setLease}/> : null}
                    </Form.Group>
                </Col>
            </Row>
            {!isMexico && !isPR1 && fusionPackages && continuity !== 'Continuity' ? <Row className="mb-3">
                <Col>
                    <FormLabel bold={true}>{labels.customerRefusedFusion}</FormLabel>
                    <FormCheck type="radio" label={labels.yesOption} value="Yes" onChange={setCustomerRefuseFusion}
                               checked={customerRefusedFusion === 'Yes'}/>
                    <FormCheck type="radio" label="No" value="No" onChange={setCustomerRefuseFusion}
                               checked={customerRefusedFusion === 'No'}/>
                </Col>
            </Row> : null}
            {!isMexico && !isPR1 && customerEligibleDishBundle && continuity !== 'Continuity' ? <Row className="mb-3">
                <Col>
                    <FormLabel bold={true}>{labels.dishBundleAccepted}</FormLabel>
                    <FormCheck type="radio" label={labels.yesOption} value="Yes" onChange={handleDishBundle}
                               checked={dishBundleAccepted === 'Yes'}/>
                    <FormCheck type="radio" label="No" value="No" onChange={handleDishBundle}
                               checked={dishBundleAccepted === 'No'}/>
                </Col>
            </Row> : null}
            {!isMexico && !isBusiness(script) ? <BroadbandFacts/> : null}
            {/*<BroadbandFacts/>*/}

        </>
    }
    return <>
        {plansComponent}
        {!isMexico ? <OptionalServices/> : null}

    </>
}

export default connect(Plans, 'plans')
